<template>
  <div class="page">
    <div class="flex column h-center">
      <img src="../../assets/404.png"
           class="banner">
      <div class="remind">您当前版本过低，请更新app版本后使用</div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Index',
  components: {},
  data () {
    return {
    }
  },
  computed: {},
  created () {

    // window.onerror = function (message, url, line, column, error) {
    //   console.log('log---onerror::::', message, url, line, column, error);
    // }
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
.page {
  background: #f6f6f6;
}
.banner {
  width: 146px;
  height: 127px;
  margin: 148px auto 0;
}
.remind {
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #433c3c;
  line-height: 20px;
}
</style>
